'use strict'
angular
	.module('cb.Letter-Template', [])
	.controller('LetterTemplateController', ["$scope", "NotificationService", "LetterGenerationService", "$modal", "$routeParams", function ($scope, NotificationService, LetterGenerationService, $modal, $routeParams) {
			$scope.init = () => {
				$scope.getLetterTemplates();
			};
			$scope.getLetterTemplates = function () {
				$scope.getLetterTemplatesPromice = LetterGenerationService.GetLetterTemplates();
				$scope.getLetterTemplatesPromice.then((output) => {
					$scope.LetterTemplates = output.data;
				}, (error) => {
					NotificationService.alert('Failed to get Letter Templates', 'there was a error when getting Letter Templates', 'error', {})
				})
			};
			$scope.Delete = function (Id) {
				var data = {
					Id: Id,
					IsDeleted: true
				};
				$scope.DeleteLetterTemplatepPromice = LetterGenerationService.UpdateLetterTemplate(data);
				$scope.DeleteLetterTemplatepPromice.then((output) => {
					$scope.init();
				}, (error) => {
					NotificationService.alert('Failed to updata Letter Templates', 'there was a error when getting Letter Templates', 'error', {})
				});
			};
			$scope.AddNewTemplate = function () {
				openModal('views/modal-templates/add-letter-template.html', 'AddLetterTemplateController');
			};
			$scope.EditTemplate = function (item) {
				openModal('views/modal-templates/edit-letter-template.html', 'EditLetterTemplateController', item);

			}

			function openModal(modalTemplate, Controller, modalData) {
				var modal = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: modalTemplate,
					controller: Controller,
					resolve: {
						data: function () {
							return modalData;
						}
					}
				});
				modal.result.finally(() => {
					$scope.init();
				})
			};

		}
	])
	.controller('AddLetterTemplateController', ["$scope", "$modalInstance", "NotificationService", "LetterGenerationService", function ($scope, $modalInstance, NotificationService, LetterGenerationService) {
		$scope.init = function () {

		};
		$scope.Save = function (Item) {
			var data = Item;
			$scope.newLetterPromice = LetterGenerationService.NewLetterTemplate(data);
			$scope.newLetterPromice.then((output) => {
				$scope.close();
			}, (error) => {
				NotificationService.alert('Failed to save Letter Templates', 'there was a error when saving Letter Templates', 'error', {})
			})
		};
		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('EditLetterTemplateController', ["$scope", "$modalInstance", "NotificationService", "LetterGenerationService", "data", function ($scope, $modalInstance, NotificationService, LetterGenerationService, data) {
		$scope.init = function () {
			$scope.getLetterTemplate(data.Id);
		};
		$scope.DeletePair = function (Item) {
			Item.IsDeleted = true;
			$scope.NewMappingPairPromice = LetterGenerationService.UpdateLetterTemplateMapping(Item);
			$scope.NewMappingPairPromice.then((output) => {
				$scope.init();
			}, (error) => {
				NotificationService.alert('Failed to Save Mapping Pair', 'there was a error when saveing Letter Template Mapping Pair', 'error', {});
			})
		};
		$scope.Save = function (item) {
			var Data = item;
			$scope.NewMappingPairPromice = LetterGenerationService.UpdateLetterTemplate(Data);
			$scope.NewMappingPairPromice.then((output) => {
				$scope.close();
			}, (error) => {
				NotificationService.alert('Failed to Save Mapping Pair', 'there was a error when saveing Letter Template Mapping Pair', 'error', {})

			})
		};
		$scope.addNew = function (Item) {
			Item.TemplateId = data.Id;
			$scope.NewMappingPairPromice = LetterGenerationService.NewLetterTemplateMapping(Item);
			$scope.NewMappingPairPromice.then((output) => {
				$scope.getLetterTemplate(data.Id);
			}, (error) => {
				NotificationService.alert('Failed to Save Mapping Pair', 'there was a error when saveing Letter Template Mapping Pair', 'error', {})

			})
		}
		$scope.getLetterTemplate = function (Id) {
			$scope.getLetterTemplatesPromice = LetterGenerationService.GetLetterTemplate(Id);
			$scope.getLetterTemplatesPromice.then((output) => {
				$scope.LetterTemplate = output.data;
			}, (error) => {
				NotificationService.alert('Failed to get Letter Templates', 'there was a error when getting Letter Templates', 'error', {})
			})
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
